import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Button, MenuItem, Divider } from "@mui/material";
import {
  Home as HomeIcon,
  Phone as PhoneIcon,
  Info as InfoIcon,
  Quiz as QuizIcon,
  LibraryBooks as BookIcon,
  ShoppingBag as ShoppingBagIcon,
  Person as PersonIcon,
  LogoutOutlined as LogoutIcon,
} from "@mui/icons-material";
import { AuthContext } from "../../contexts/AuthContext";

const menuTextStyle = {
  marginLeft: "10px",
};

const Menu = ({
  handleNavigate,
  mainMenuItemStyle,
  dividerProps,
  authButtonColor,
  ...props
}) => {
  const navigate = useNavigate();
  const { authorized, signOut } = useContext(AuthContext);

  const handleSignOut = async () => {
    await signOut();
    navigate("/");
  };

  return (
    <Box {...props}>
      <MenuItem
        style={mainMenuItemStyle}
        onClick={() => handleNavigate("/chatbot")}
      >
        <HomeIcon />
        <span style={menuTextStyle}>Assistant</span>
      </MenuItem>
      <MenuItem
        style={mainMenuItemStyle}
        onClick={() => handleNavigate("/pricing")}
      >
        <ShoppingBagIcon />
        <span style={menuTextStyle}>Pricing</span>
      </MenuItem>
      <MenuItem
        style={mainMenuItemStyle}
        onClick={() => handleNavigate("/contact")}
      >
        <PhoneIcon />
        <span style={menuTextStyle}>Contact</span>
      </MenuItem>
      <Divider {...dividerProps} />
      <MenuItem onClick={() => handleNavigate("/faq")}>
        <QuizIcon />
        <span style={menuTextStyle}>FAQ</span>
      </MenuItem>
      <MenuItem onClick={() => handleNavigate("/glossary")}>
        <BookIcon />
        <span style={menuTextStyle}>Glossary</span>
      </MenuItem>
      <MenuItem onClick={() => handleNavigate("/about")}>
        <InfoIcon />
        <span style={menuTextStyle}>About</span>
      </MenuItem>
      <Divider {...dividerProps} />
      {authorized ? (
        <>
          <MenuItem onClick={() => handleNavigate("/profile")}>
            <PersonIcon />
            <span style={menuTextStyle}>Profile</span>
          </MenuItem>
          <Divider {...dividerProps} />
          <MenuItem
            color={authButtonColor}
            aria-label="log out"
            onClick={() => handleSignOut()}
          >
            <LogoutIcon />
          </MenuItem>
        </>
      ) : (
        <MenuItem>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
              width: "100%",
            }}
          >
            <Button
              color={authButtonColor}
              variant="outlined"
              sx={{ width: "100%" }}
              onClick={() => handleNavigate("/sign-in")}
            >
              Sign In
            </Button>
            <Button
              color={authButtonColor}
              variant="contained"
              sx={{ width: "100%" }}
              onClick={() => handleNavigate("/sign-up")}
            >
              Join
            </Button>
          </Box>
        </MenuItem>
      )}
    </Box>
  );
};

export default Menu;
