import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from "react-router-dom";

import { 
  Box, Typography, Button, TextField 
} from '@mui/material';

import config from '../../settings/config';
import { getToken } from '../../settings/storage';

export default function EditAccount() {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    email: ''
  });

  useEffect(() =>{
    getToken().then((token) => {
      if (token) {
        axios.get(`${config.backendUrl}/client/profile`, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          }
        }).then((response) => {
          // console.log(response)
          if (response.status === 200 && response.data.data) {
            setFormData(response.data.data);
          }
        }).catch((error) => {
          console.error('Error fetching profile:', error);
          if (error.response.status === 401) {
            navigate("/sign-in");
          }
        });
      }
    });
  }, [navigate])

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = () => {
    // console.log(formData)
    getToken().then((token) => {
      axios.put(`${config.backendUrl}/client/profile`, {first_name: formData.first_name, last_name: formData.last_name}, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        }
      }).then((response) => {
        // console.log(response)
        alert(response.data.message);
      }).catch((error) => {
        console.error('Error saving profile:', error);
      });
    })
  };

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      gap: 3,
    }}>
      <Typography variant="h5" color="text.primary" sx={{mb: 1, fontWeight: 'bold'}}>
        My Account
      </Typography>
      <Box sx={{
        width: {xs: '100%', sm: '80%', md: '70%', lg: '60%'},
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
      }}>
        <Typography>First Name:</Typography>
        <TextField
          name="first_name"
          value={formData.first_name}
          size="small"
          sx={{
            minWidth: {xs: '150px', sm: '250px', md: '300px'} 
          }}
          onChange={handleChange}
        />
      </Box>
      <Box sx={{
        width: {xs: '100%', sm: '80%', md: '70%', lg: '60%'},
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
      }}>
        <Typography>Last Name:</Typography>
        <TextField
          name="last_name"
          value={formData.last_name}
          size="small"
          sx={{
            minWidth: {xs: '150px', sm: '250px', md: '300px'} 
          }}
          onChange={handleChange}
        />
      </Box>
      <Box sx={{
        width: {xs: '100%', sm: '80%', md: '70%', lg: '60%'},
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
      }}>
        <Typography>Email:</Typography>
        <TextField
          name="email"
          value={formData.email}
          type="email"
          size="small"
          sx={{
            minWidth: {xs: '150px', sm: '250px', md: '300px'} 
          }}
          disabled
        />
      </Box>
      <Box sx={{
        mt: 2
      }}>
        <Button 
          variant="contained" 
          onClick={handleSubmit}
        >
          Save Changes
        </Button>
      </Box>
    </Box>
  );
}