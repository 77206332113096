import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Drawer,
  Box,
  IconButton,
  AppBar,
  Toolbar,
  Container,
} from "@mui/material";
import { Menu as MenuIcon } from "@mui/icons-material";
import Menu from "../utils/Menu";

const logoStyle = {
  width: "110px",
  height: "105px",
  cursor: "pointer",
};
const mainMenuItemStyle = {
  textDecoration: "none",
  color: "rebeccapurple",
};

const Header = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const toggleDrawer = (newOpen) => setOpen(newOpen);

  const handleNavigate = (path) => {
    navigate(path);
    setOpen(false); // Close the drawer after navigation
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        position="absolute"
        sx={{
          boxShadow: 0,
          bgcolor: "header.secondary",
          backgroundImage: "none",
        }}
      >
        <Toolbar
          variant="regular"
          sx={{
            py: "5px",
          }}
        >
          <Container
            maxWidth="lg"
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              flexShrink: 0,
            }}
          >
            {/* Logo */}
            <Box
              sx={{
                flexGrow: 1,
                display: "flex",
                alignItems: "center",
                px: 0,
              }}
            >
              <Link to="/">
                <img
                  src={"/logo192.png"}
                  style={logoStyle}
                  alt="Homeguard Logo"
                />
              </Link>
            </Box>

            {/* Title */}
            {/* <Typography
              sx={{
                textAlign: "center",
                color: "black",
                flexGrow: 1,
              }}
            >
              HOMEGUARD AI ASSISTANT
            </Typography> */}

            {/* Desktop Menu */}
            <Menu
              sx={{
                display: { xs: "none", md: "flex" },
                // color: "black",
                gap: 2,
                alignItems: "center",
                mr: "-18px",
              }}
              dividerProps={{orientation:"vertical", variant:"middle", flexItem: true}}
              handleNavigate={handleNavigate}
              authButtonColor="none"
            />

            {/* Mobile Menu */}
            <Box sx={{ display: { xs: "block", md: "none" } }}>
              <IconButton
                variant="text"
                aria-label="menu"
                onClick={() => toggleDrawer(true)}
              >
                <MenuIcon />
              </IconButton>
              <Drawer
                anchor="left"
                open={open}
                onClose={() => toggleDrawer(false)}
              >
                <Menu
                  sx={{
                    minWidth: "35dvw",
                    p: 2,
                    backgroundColor: "background.paper",
                    flexGrow: 1,
                  }}
                  mainMenuItemStyle={mainMenuItemStyle}
                  handleNavigate={handleNavigate}
                />
              </Drawer>
            </Box>
          </Container>
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default Header;
