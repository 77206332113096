export const fetchBotResponse = async () => {
  try {
    const response = await fetch(
      "https://jsonplaceholder.typicode.com/todos/1"
    );
    const data = await response.json();
    return data.title;
  } catch (error) {
    return "Oh, no. I can't help you now!";
  }
};
