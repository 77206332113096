import React, { useState, useEffect, useContext } from "react";
import { useNavigate, Link } from "react-router-dom";
import { toast } from "react-toastify";

import CircularProgress from "@mui/material/CircularProgress";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Grid2 from "@mui/material/Grid2";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Alert from "@mui/material/Alert";
import Container from "@mui/material/Container";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";

import { signIn, generateQrCode, checkQrToken } from "../../services/authService";
import { AuthContext } from "../../contexts/AuthContext";

import "./auth.css";

export default function SignIn() {
  const { setAuthorized } = useContext(AuthContext);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [qrOpen, setQrOpen] = useState(false);
  const [qrCode, setQrCode] = useState(null);
  const [qrToken, setQrToken] = useState(null);
  const [qrLoading, setQrLoading] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const email = data.get("email");
    const password = data.get("password");

    setError(null);

    if (!email) {
      setError("Email is required");
      return;
    }
    if (!password) {
      setError("Password is required");
      return;
    } else if (password.length < 8) {
      setError("Password must be at least 8 characters long.");
      return;
    }

    setIsLoading(true);

    try {
      await signIn(email, password);
      setAuthorized(true);
      toast.success("Signed in successfully!", { autoClose: 2000 });
      navigate("/chatbot");
    } catch (err) {
      setError(err?.message);
      toast.error(err.message, { autoClose: 2000 });
    } finally {
      setIsLoading(false);
    }
  };

  const handleQrLogin = async () => {
    setQrLoading(true);
    try {
      const response = await generateQrCode();
      console.log('checkpoint qrlogin', response)
      setQrCode(response.qr_data); // Base64 image
      setQrToken(response.qr_token); // Token to poll with
      setQrOpen(true);
    } catch (err) {
      toast.error("Failed to generate QR code");
    } finally {
      setQrLoading(false);
    }
  };

  // Poll backend to check QR token status
  useEffect(() => {
    if (!qrToken) return;

    const interval = setInterval(async () => {
      try {
        const response = await checkQrToken(qrToken);
        if (response.is_valid_qr_token) {
          clearInterval(interval);
          setAuthorized(true);
          setQrOpen(false);
          toast.success("Signed in successfully!", { autoClose: 2000 });
          navigate("/chatbot");
        }
      } catch (error) {
        console.error("Error checking QR login status", error);
      }
    }, 3000);

    return () => clearInterval(interval);
  }, [qrToken, setAuthorized, navigate]);

  return (
    <Grid2 container component="main" sx={{ height: "100vh" }}>
      <Grid2 className="background-container" size={{ xs: 12, sm: 6 }} />
      <Grid2
        container
        size={{ xs: 12, sm: 6 }}
        sx={{ backgroundColor: "white" }}
        justifyContent="center"
      >
        <Container
          maxWidth="sm"
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            boxShadow: { xs: 3, sm: 0 },
          }}
        >
          {isLoading && <CircularProgress />}
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            />
            {error && <Alert severity="error">{error}</Alert>}
            <Button
              color="secondary"
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              disabled={isLoading}
            >
              Sign In
            </Button>

            <Button
              color="primary"
              fullWidth
              variant="contained"
              onClick={handleQrLogin}
              sx={{ mt: 2, mb: 2 }}
              disabled={qrLoading}
            >
              {qrLoading ? <CircularProgress size={24} /> : "Sign In with QR Code"}
            </Button>

            <Grid2 container>
              <Grid2>
                <Link to="/sign-up" variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid2>
            </Grid2>
          </Box>

          {/* QR Code Dialog */}
          <Dialog open={qrOpen} onClose={() => setQrOpen(false)}>
            <DialogTitle>Scan QR Code to Signin</DialogTitle>
            <DialogContent>
              {qrCode ? (
                <img src={qrCode} alt="QR Code" />
              ) : (
                <CircularProgress />
              )}
            </DialogContent>
          </Dialog>
        </Container>
      </Grid2>
    </Grid2>
  );
}
