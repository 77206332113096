import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

import { 
  Box, 
  Typography, 
  Table, 
  TableHead, 
  TableRow, 
  TableBody, 
  Button, 
  Dialog, 
  DialogActions, 
  DialogContent, 
  DialogTitle, 
} from '@mui/material';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import config from '../../settings/config';
import { getToken } from '../../settings/storage';

export default function MySubscription() {
  const navigate = useNavigate();

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const [rows, setRows] = useState([]);
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.common.white,
      fontSize: '1rem',
      fontWeight: 'bold',
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: '1rem',
      fontWeight: 'bold',
    },
  }));

  useEffect(() => {
    getToken().then((token) => {
      if (token) {
        axios.get(`${config.backendUrl}/client/profile/active_subscription`, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          }
        }).then((response) => {
          // console.log(response)
          if (response && response.status === 200) {
            setRows(response.data.data ? [response.data.data] : []);
          }
        }).catch((error) => {
          console.error('Error creating checkout session:', error);
          if (error.response.status === 401) {
            navigate("/sign-in");
          }
        });
      }
    });
  }, [])

  const cancelSubscription = () => {
    getToken().then((token) => {
      if (token) {
        axios.get(`${config.backendUrl}/client/subscriptions/cancel-subscription`, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
        }).then((response) => {
          // console.log(response)
          if (response && response.status === 200) {
            setConfirmationDialogOpen(false);
            alert("Subscription successfully cancelled.");
            axios.get(`${config.backendUrl}/client/profile/active_subscription`, {
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
              }
            }).then((response) => {
              console.log(response)
              if (response && response.status === 200) {
                setRows(response.data.data ? [response.data.data] : []);
              }
            }).catch((error) => {
              console.error('Error creating checkout session:', error);
              if (error.response.status === 401) {
                navigate("/sign-in");
              }
            });
          }
        }).catch((error) => {
          setConfirmationDialogOpen(false);
          console.error('Error cancel subscription:', error);
          if (error && error.response && error.response.status === 401) {
            navigate("/sign-in");
          }
        });
      }
    });
  }
  const openConfirmationDialog = () => {
    setConfirmationDialogOpen(true);
  }
  const closeConfirmationDialog = () => {
    setConfirmationDialogOpen(false);
  }
  function ConfirmationDialog() {
    return (
      <Dialog
        fullScreen={fullScreen}
        open={confirmationDialogOpen}
        onClose={closeConfirmationDialog}
        aria-labelledby="cancel-subscription-dialog"
      >
        <DialogTitle id="cancel-subscription-dialog">
          {"Are you sure you want to cancel the current product?"}
        </DialogTitle>
        <DialogContent>

        </DialogContent>
        <DialogActions>
          <Button autoFocus color="secondary" onClick={closeConfirmationDialog}>
            Cancel
          </Button>
          <Button 
            onClick={cancelSubscription}
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      gap: 3,
    }}>
      <Typography variant="h5" color="text.primary" sx={{mb: 1, fontWeight: 'bold'}}>
        Current Products
      </Typography>
      <Box sx={{
        width: '100%',
        overflowX: {
          xs: 'auto', // Allow horizontal scroll on xs and sm
          sm: 'auto',
          md: 'hidden' // No scroll on medium and larger screens
        },
      }}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Product</StyledTableCell>
              <StyledTableCell align="center">Price</StyledTableCell>
              <StyledTableCell align="center">Activation Date</StyledTableCell>
              <StyledTableCell align="center">Exp/Renewal Date</StyledTableCell>
              <StyledTableCell align="right">Action</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.length > 0 && rows.map((row) => (
              <TableRow
                key={row.plan_name}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <StyledTableCell component="th" scope="row">
                  {row.plan_title}
                </StyledTableCell>
                <StyledTableCell align="center">{row.plan_price + " " + row.plan_currency}</StyledTableCell>
                <StyledTableCell align="center">{row.start_date}</StyledTableCell>
                <StyledTableCell align="center">{row.end_date}</StyledTableCell>
                <StyledTableCell align="right">
                  <Button 
                    variant="outlined"
                    color="primary"
                    size="small"
                    sx={{
                      mr: 1,
                    }}
                    onClick={()=>navigate("/pricing")}
                  >
                    Update
                  </Button>
                  <Button 
                    variant="text"
                    color="secondary"
                    size="small"
                    onClick={() => openConfirmationDialog()}
                  >
                    Cancel
                  </Button>
                </StyledTableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
      <ConfirmationDialog />
    </Box>
  );
}