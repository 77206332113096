import axios from "axios";
import config from "../../settings/config";
import { getToken } from "../../settings/storage";

// Create inventory item with file upload handling
export const createInventoryItem = async (payload) => {
  try {
    const token = await getToken();
    const formData = new FormData();

    for (const key in payload.inventory_item) {
      formData.append(key, payload.inventory_item[key]);
    }
    if (payload.images && payload.images.length > 0) {
      payload.images.forEach((image) => {
        if (image instanceof File) {
          formData.append("images", image); // Append each image
        } else {
          console.warn("Invalid image file:", image);
        }
      });
    }
    console.log("checkpoint form data");
    for (const pair of formData.entries()) {
      console.log(`${pair[0]}: ${pair[1]}`);
    }

    const response = await axios.post(
      `${config.backendUrl}/client/inventory_items`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          // 'Content-Type': 'multipart/form-data', // Axios automatically sets this when using FormData
        },
      }
    );

    return response.data; // Return the response data
  } catch (error) {
    console.error("Error creating inventory item:", error);
    throw error; // Rethrow the error to be caught by the caller
  }
};

// Fetch all inventory items for the current user
export const fetchAllInventoryItems = async () => {
  try {
    const token = await getToken();

    const response = await axios.get(
      `${config.backendUrl}/client/inventory_items`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data; // Return the response data
  } catch (error) {
    console.error("Error fetching inventory items:", error);
    throw error;
  }
};

// Fetch inventory items with pagination and search
export const fetchInventoryItems = async (page, rowsPerPage, delayedSearch, roomName, sortBy = "updated_at", sortOrder=-1) => {
  try {
    const token = await getToken();

    const response = await axios.get(
      `${config.backendUrl}/client/inventory_items`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          current_page: page,
          limit: rowsPerPage,
          sort_by: sortBy,
          sort_order: sortOrder,
          search: delayedSearch,
          room_name: roomName,
        },
      }
    );

    return response.data; // Return the response data
  } catch (error) {
    console.error("Error fetching inventory items:", error);
    throw error;
  }
};

// Fetch a specific inventory item by ID
export const fetchInventoryItemById = async (id) => {
  try {
    const token = await getToken();

    const response = await axios.get(
      `${config.backendUrl}/client/inventory_items/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data; // Return the response data
  } catch (error) {
    console.error("Error fetching inventory item by ID:", error);
    throw error;
  }
};

// Update an inventory item by ID
export const updateInventoryItem = async (id, payload) => {
  try {
    console.log("checkpoint input form data:", payload);
    const token = await getToken();
    const formData = new FormData();

    // Append updated inventory item fields to formData
    for (const key in payload.inventory_item) {
      const value = payload.inventory_item[key];
      if (value !== null && value !== undefined) {
        formData.append(key, value);
      }
    }
    if (payload.images && payload.images.length > 0) {
      payload.images.forEach((image) => {
        if (image instanceof File) {
          formData.append("images", image); // Append each image
        } else {
          console.warn("Invalid image file:", image);
        }
      });
    }
    console.log("checkpoint form data");
    for (const pair of formData.entries()) {
      console.log(`${pair[0]}: ${pair[1]}`);
    }

    const response = await axios.put(
      `${config.backendUrl}/client/inventory_items/${id}`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data; // Return the response data
  } catch (error) {
    console.error("Error updating inventory item:", error);
    throw error;
  }
};

// Delete an inventory item by ID
export const deleteInventoryItem = async (id) => {
  try {
    const token = await getToken();

    const response = await axios.delete(
      `${config.backendUrl}/client/inventory_items/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data; // Return the response data
  } catch (error) {
    console.error("Error deleting inventory item:", error);
    throw error;
  }
};
