import { Box, Typography } from "@mui/material";

const Header = () => {
  return (
    <Box
      sx={{
        backgroundColor: "header.main",
        textAlign: "center",
        padding: { xs: 1, sm: 2 },
      }}
    >
      <Typography
        sx={{
          color: "title.main",
          fontSize: { xs: "1.5rem", sm: "2rem", md: "2.5rem" },
        }}
        variant="h3"
        component="h1"
        gutterBottom
      >
        HOMEGUARD AI ASSISTANT
      </Typography>
      <Typography
        sx={{
          color: "subtitle.main",
          fontSize: { xs: "1rem", sm: "1.25rem", md: "1.5rem" },
          lineHeight: { xs: 1.3, sm: 1.5, md: 1.75 },
        }}
        variant="h5"
        gutterBottom
      >
        Your smart AI assistant for disaster preparedness and home inventory
        management.
      </Typography>
    </Box>
  );
};

export default Header;
