import React, { useState } from "react";

import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";

import Header from "../components/layouts/ContentHeader";
import Footer from "../components/layouts/ContentFooter";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import faqData from "../consts/faqData";

export default function FAQ() {
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Box>
      <Header />
      <Box
        sx={{
          width: "100%",
          height: "100vh",
          backgroundSize: "100% 50%",
          backgroundRepeat: "no-repeat",
        }}
      >
        <Container
          sx={{
            pt: 20,
            pb: { xs: 4, sm: 6 },
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: { xs: 3, sm: 6 },
          }}
        >
          <Typography
            component="h2"
            variant="h4"
            color="text.primary"
            sx={{
              width: { sm: "100%", md: "60%" },
              textAlign: { xs: "center", md: "center" },
            }}
          >
            Frequently asked questions (FAQ)
          </Typography>

          <Box sx={{ width: "100%" }}>
            {faqData.map(({ id, question, answer }) => (
              <Accordion
                key={id}
                expanded={expanded === id}
                onChange={handleChange(id)}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={`${id}-content`}
                  id={`${id}-header`}
                >
                  <Typography
                    component="h3"
                    variant="subtitle2"
                    sx={{ fontWeight: "bold" }}
                  >
                    {question}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography
                    variant="body2"
                    gutterBottom
                    sx={{ maxWidth: { sm: "100%", md: "70%" } }}
                  >
                    {answer}
                  </Typography>
                </AccordionDetails>
              </Accordion>
            ))}
          </Box>
        </Container>
      </Box>
      <Footer />
    </Box>
  );
}
