import React, { useState } from 'react';
import axios from 'axios';
import { 
  Box, Typography, Button, TextField, Alert 
} from '@mui/material';

import config from '../../settings/config';
import { getToken } from '../../settings/storage';

export default function ChangePassword() {
  const [formData, setFormData] = useState({
    current_password: '',
    new_password: '',
    confirm_password: ''
  });
  const [error, setError] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = () => {
    setError(null);
    // console.log(formData)
    if (!formData.current_password) {
      setError("Current password is required.");
      return;
    } else if (formData.new_password.length < 8) {
      setError("Password must be at least 8 characters long.");
      return;
    } else if (formData.new_password !== formData.confirm_password) {
      setError("Password must be confirmed.");
      return;
    }

    getToken().then((token) => {
      if (token) {
        axios.put(`${config.backendUrl}/client/profile/change-password`, {current_password: formData.current_password, new_password: formData.new_password}, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          }
        }).then((response) => {
          // console.log(response)
          if (response.status === 200) {
            if (response.data.error) {
              setError(response.data.message);
            } else {
              alert(response.data.message);
            }
          }
        }).catch((error) => {
          setError(error?.message);
          console.error('Error saving profile:', error);
        });
      }
    })
  };

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      gap: 3,
    }}>
      <Typography variant="h5" color="text.primary" sx={{mb: 1, fontWeight: 'bold'}}>
        Change Password
      </Typography>
      <Box sx={{
        width: {xs: '100%', sm: '80%', md: '70%', lg: '60%'},
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
      }}>
        <Typography>Current Password:</Typography>
        <TextField
          name="current_password"
          type="password"
          size="small"
          sx={{
            minWidth: {xs: '150px', sm: '250px', md: '300px'} 
          }}
          onChange={handleChange}
        />
      </Box>
      <Box sx={{
        width: {xs: '100%', sm: '80%', md: '70%', lg: '60%'},
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
      }}>
        <Typography>New Password:</Typography>
        <TextField
          name="new_password"
          type="password"
          size="small"
          sx={{
            minWidth: {xs: '150px', sm: '250px', md: '300px'} 
          }}
          onChange={handleChange}
        />
      </Box>
      {formData.new_password && <Box sx={{
        width: {xs: '100%', sm: '80%', md: '70%', lg: '60%'},
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
      }}>
        <Typography>Confirm Password:</Typography>
        <TextField
          name="confirm_password"
          type="password"
          size="small"
          sx={{
            minWidth: {xs: '150px', sm: '250px', md: '300px'} 
          }}
          onChange={handleChange}
        />
      </Box>}
      {error && <Alert severity="error">{error}</Alert>}
      <Box sx={{
        mt: 2
      }}>
        <Button 
          variant="contained" 
          onClick={handleSubmit}
        >
          Save Changes
        </Button>
      </Box>
    </Box>
  );
}