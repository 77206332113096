import React from "react";
import {
  Grid2,
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Divider,
} from "@mui/material";
import ImagesViewBox from "./ImagesViewBox";

export default function InventoryViewDialog({
  open,
  title,
  inventoryData,
  handleClose,
}) {
  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Box>
          {inventoryData && (
            <Grid2 container spacing={2}>
              <Grid2 container size={12}>
                <Grid2 size={{ xs: 6, sm: 6 }}>
                  <p>
                    <strong>Room Name:</strong>{" "}
                    {inventoryData.roomName || "N/A"}
                  </p>
                </Grid2>
                <Grid2 size={{ xs: 6, sm: 6 }}>
                  <p>
                    <strong>Room Type:</strong>{" "}
                    {inventoryData.roomType || "N/A"}
                  </p>
                </Grid2>
              </Grid2>

              <Grid2 size={12}>
                <Divider variant="middle" />
              </Grid2>

              <Grid2 container size={{ xs: 12, sm: 8 }}>
                <Grid2 size={{ xs: 6, sm: 6 }}>
                  <p>
                    <strong>Quantity:</strong> {inventoryData.quantity || "N/A"}
                  </p>
                </Grid2>

                <Grid2 size={{ xs: 6, sm: 6 }}>
                  <p>
                    <strong>Brand:</strong> {inventoryData.brand || "N/A"}
                  </p>
                </Grid2>

                <Grid2 size={{ xs: 6, sm: 6 }}>
                  <p>
                    <strong>Model:</strong> {inventoryData.model || "N/A"}
                  </p>
                </Grid2>

                <Grid2 size={{ xs: 6, sm: 6 }}>
                  <p>
                    <strong>Internal Comments:</strong>{" "}
                    {inventoryData.internalComments || "N/A"}
                  </p>
                </Grid2>

                <Grid2 size={{ xs: 12, sm: 12 }}>
                  <p>
                    <strong>Description:</strong> {inventoryData.description}
                  </p>
                </Grid2>

                <Grid2 size={{ xs: 6, sm: 6 }}>
                  <p>
                    <strong>Place of Purchase:</strong>{" "}
                    {inventoryData.placeOfPurchase || "N/A"}
                  </p>
                </Grid2>

                <Grid2 size={{ xs: 6, sm: 6 }}>
                  <p>
                    <strong>Purchase Date:</strong> {inventoryData.purchaseDate}
                  </p>
                </Grid2>

                <Grid2 size={{ xs: 12, sm: 6, md: 6 }}>
                  <p>
                    <strong>Insured Replacement Price:</strong>{" "}
                    {inventoryData.insuredOrigCost
                      ? `${inventoryData.insuredOrigCost} ${inventoryData.insuredOrigCostCurrency}`
                      : "N/A"}
                  </p>
                </Grid2>

                <Grid2 size={{ xs: 12, sm: 6, md: 6 }}>
                  <p>
                    <strong>Insured Replacement Price:</strong>{" "}
                    {inventoryData.insuredReplacementPrice
                      ? `${inventoryData.insuredReplacementPrice} ${inventoryData.insuredReplacementCurrency}`
                      : "N/A"}
                  </p>
                </Grid2>

                {/* 5th Row: Age Years, Age Months, */}
                <Grid2 size={{ xs: 6, sm: 6 }}>
                  <p>
                    <strong>Age (Years):</strong>{" "}
                    {inventoryData.ageYears || "N/A"}
                  </p>
                </Grid2>

                <Grid2 size={{ xs: 6, sm: 6 }}>
                  <p>
                    <strong>Age (Months):</strong>{" "}
                    {inventoryData.ageMonths || "N/A"}
                  </p>
                </Grid2>

                {/* Group: Condition, Antique */}
                <Grid2 size={{ xs: 6, sm: 6 }}>
                  <p>
                    <strong>Condition:</strong>{" "}
                    {inventoryData.condition || "N/A"}
                  </p>
                </Grid2>

                <Grid2 size={{ xs: 6, sm: 6 }}>
                  <p>
                    <strong>Antique:</strong>{" "}
                    {inventoryData.antique ? "Yes" : "No"}
                  </p>
                </Grid2>
              </Grid2>

              {/* Image Section */}
              <Grid2
                size={{ xs: 12, sm: 4 }}
                container
                direction="column"
                alignItems={{ xs: "center", sm: "flex-start" }}
              >
                {/* Display image previews */}
                <Grid2 container spacing={2}>
                  <ImagesViewBox titles={inventoryData.imageFilenames} imagePreviews={inventoryData.imageUrls} />
                </Grid2>
              </Grid2>
            </Grid2>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
