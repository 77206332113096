import React from "react";
import { Typography, Box } from "@mui/material";

const AnalysisResult = ({ analysis }) => {
  // Convert the plain text into HTML elements
  const FormattedAnalysis = () => {
    if (analysis) {
      return analysis.split("\n\n").map((paragraph, index) => {
        // Check for specific keywords to apply additional formatting
        if (
          paragraph.includes("Actual Cash Value (ACV):") ||
          paragraph.includes("Replacement Cost Value (RCV):") ||
          paragraph.includes("Coverage Limits:") ||
          paragraph.includes("Exclusions:") ||
          paragraph.includes("More Feedback:")
        ) {
          return (
            <Typography
              variant="h6"
              key={index}
              style={{ fontWeight: "bold", marginTop: "16px" }}
            >
              {paragraph}
            </Typography>
          );
        }

        return (
          <Typography
            variant="body1"
            key={index}
            style={{ marginBottom: "12px" }}
          >
            {paragraph}
          </Typography>
        );
      });
    } else {
      return <></>;
    }
  };

  return (
    <Box
      sx={{
        p: 3,
        borderRadius: "22px",
        borderColor: "#42B4C5",
        bgcolor: "#773ad6", // same bot message color #491d8d
        color: "white", 
        marginTop: "8px",
        marginRight: { xs: "32px", sm: "64px" },
        marginLeft: "16px",
      }}
    >
      <FormattedAnalysis />
    </Box>
  );
};

export default AnalysisResult;
