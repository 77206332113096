import React from "react";
import {
  TextField,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Button,
  Grid2,
  Autocomplete,
  Divider,
  IconButton,
} from "@mui/material";
import {
  CloudUpload as CloudUploadIcon,
  ArrowForward as NextIcon,
} from "@mui/icons-material";
import ImagesPreview from "./ImagesPreview";
import {
  currencies,
  brands,
  conditions,
  roomTypes,
} from "../../consts/categories";

const InventoryInputFields = ({
  inventoryData,
  imagePreviews,
  titles,
  handleNewRoomClick,
  handleChange,
  // handleBrandChange,
  // handleRoomTypeChange,
  handleSelectImage,
  handleDeleteImage,
  isFormDisabled=false,
}) => {
  console.log("checkpoint inventory data:", inventoryData);
  return (
    <Grid2 container spacing={2}>
      <Grid2 container size={12}>
        <Grid2 container size={{ xs: 12, sm: 8 }}>
          <Grid2 size={{ xs: 6, sm: 6 }}>
            <Autocomplete
              autoHighlight
              autoSelect
              options={roomTypes}
              value={inventoryData.roomType || ""}
              renderInput={(params) => (
                <TextField
                  label="Room Type"
                  name="room_type"
                  {...params}
                  onChange={handleChange}
                />
              )}
              // onChange={handleRoomTypeChange}
              disabled={isFormDisabled}
            />
          </Grid2>
          <Grid2 size={{ xs: 6, sm: 6 }}>
            <TextField
              label="Room Name"
              name="roomName"
              value={inventoryData.roomName || ""}
              onChange={handleChange}
              fullWidth
              required
              disabled={isFormDisabled}
            />
          </Grid2>
        </Grid2>
        <Grid2 container size={{ xs: 12, sm: 4 }}>
          <IconButton
            aria-label="next"
            color="primary"
            onClick={handleNewRoomClick}
            disabled={isFormDisabled}
          >
            <span>New Room</span>
            <NextIcon />
          </IconButton>
        </Grid2>
      </Grid2>

      <Grid2 size={12}>
        <Divider variant="middle" />
      </Grid2>

      <Grid2 container size={{ xs: 12, sm: 8 }}>
        <Grid2 item="true" size={{ xs: 6, sm: 6 }}>
          <TextField
            label="Quantity"
            name="quantity"
            type="number"
            slotProps={{
              input: {
                min: 1,
              },
            }}
            value={inventoryData.quantity || 1}
            onChange={handleChange}
            fullWidth
            required
            disabled={isFormDisabled}
          />
        </Grid2>

        <Grid2 item="true" size={{ xs: 6, sm: 6 }}>
          <Autocomplete
            freeSolo
            disableClearable
            options={brands}
            value={inventoryData.brand || ""}
            renderInput={(params) => (
              <TextField
                label="Brand"
                name="brand"
                {...params}
                onChange={handleChange}
              />
            )}
            disabled={isFormDisabled}
          />
        </Grid2>

        <Grid2 item="true" size={{ xs: 6, sm: 6 }}>
          <TextField
            label="Model"
            name="model"
            value={inventoryData.model || ""}
            onChange={handleChange}
            fullWidth
            disabled={isFormDisabled}
          />
        </Grid2>

        <Grid2 item="true" size={{ xs: 6, sm: 6 }}>
          <TextField
            label="Internal Comments"
            name="internalComments"
            value={inventoryData.internalComments || ""}
            onChange={handleChange}
            fullWidth
            disabled={isFormDisabled}
          />
        </Grid2>

        <Grid2 item="true" size={{ xs: 12, sm: 12 }}>
          <TextField
            label="Description"
            name="description"
            value={inventoryData.description || ""}
            onChange={handleChange}
            fullWidth
            multiline
            rows={1}
            required
            disabled={isFormDisabled}
          />
        </Grid2>

        <Grid2 item="true" size={{ xs: 6, sm: 6 }}>
          <TextField
            label="Place of Purchase"
            name="placeOfPurchase"
            value={inventoryData.placeOfPurchase || ""}
            onChange={handleChange}
            fullWidth
            disabled={isFormDisabled}
          />
        </Grid2>

        <Grid2 item="true" size={{ xs: 6, sm: 6 }}>
          <TextField
            label="Purchase Date"
            name="purchaseDate"
            type="date"
            slotProps={{
              inputLabel: {
                shrink: true,
              },
            }}
            value={inventoryData.purchaseDate || ""}
            onChange={handleChange}
            fullWidth
            required
            disabled={isFormDisabled}
          />
        </Grid2>

        <Grid2 item="true" size={{ xs: 12, sm: 12, md: 6 }}>
          <Grid2 container>
            <Grid2 size={8}>
              <TextField
                label="Insured Original Cost"
                name="insuredOrigCost"
                type="number"
                slotProps={{
                  input: {
                    min: 0,
                  },
                }}
                value={inventoryData.insuredOrigCost || ""}
                onChange={handleChange}
                fullWidth
                disabled={isFormDisabled}
              />
            </Grid2>
            <Grid2 size={4}>
              <Select
                name="insuredOrigCostCurrency"
                value={inventoryData.insuredOrigCostCurrency || "USD"}
                onChange={handleChange}
                displayEmpty
                disabled={isFormDisabled}
              >
                {currencies.map((currency) => (
                  <MenuItem key={currency} value={currency}>
                    {currency}
                  </MenuItem>
                ))}
              </Select>
            </Grid2>
          </Grid2>
        </Grid2>

        <Grid2 item="true" size={{ xs: 12, sm: 12, md: 6 }}>
          <Grid2 container>
            <Grid2 size={8}>
              <TextField
                label="Insured Replacement Price"
                name="insuredReplacementPrice"
                type="number"
                slotProps={{
                  input: {
                    min: 0,
                  },
                }}
                value={inventoryData.insuredReplacementPrice || ""}
                onChange={handleChange}
                fullWidth
                disabled={isFormDisabled}
              />
            </Grid2>
            <Grid2 size={{ xs: 4, sm: 4 }}>
              <Select
                name="insuredReplacementCurrency"
                value={inventoryData.insuredReplacementCurrency || "USD"}
                onChange={handleChange}
                displayEmpty
                disabled={isFormDisabled}
              >
                {currencies.map((currency) => (
                  <MenuItem key={currency} value={currency}>
                    {currency}
                  </MenuItem>
                ))}
              </Select>
            </Grid2>
          </Grid2>
        </Grid2>

        {/* 5th Row: Age Years, Age Months, */}
        <Grid2 item="true" size={{ xs: 6, sm: 3 }}>
          <TextField
            label="Age (Years)"
            name="ageYears"
            type="number"
            slotProps={{
              input: {
                min: 0,
              },
            }}
            value={inventoryData.ageYears || ""}
            onChange={handleChange}
            fullWidth
            disabled={isFormDisabled}
          />
        </Grid2>

        <Grid2 item="true" size={{ xs: 6, sm: 3 }}>
          <TextField
            label="Age (Months)"
            name="ageMonths"
            type="number"
            slotProps={{
              input: {
                min: 0,
              },
            }}
            value={inventoryData.ageMonths || ""}
            onChange={handleChange}
            fullWidth
            disabled={isFormDisabled}
          />
        </Grid2>

        {/* Group: Condition, Antique */}
        <Grid2 item="true" size={{ xs: 6, sm: 3 }}>
          <FormControl fullWidth>
            <InputLabel>Condition</InputLabel>
            <Select
              label="Condition"
              name="condition"
              value={inventoryData.condition || ""}
              onChange={handleChange}
              disabled={isFormDisabled}
            >
              {conditions.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid2>

        <Grid2 item="true" size={{ xs: 6, sm: 3 }}>
          <FormControl fullWidth>
            <InputLabel>Antique</InputLabel>
            <Select
              label="Antique"
              name="antique"
              value={inventoryData.antique || ""}
              onChange={handleChange}
              disabled={isFormDisabled}
            >
              <MenuItem value="true">Yes</MenuItem>
              <MenuItem value="false">No</MenuItem>
            </Select>
          </FormControl>
        </Grid2>
      </Grid2>
      {/* Image Upload Section */}
      <Grid2
        size={{ xs: 12, sm: 4 }}
        container
        direction="column"
        alignItems={{ xs: "center", sm: "flex-start" }}
      >
        <Button
          variant="contained"
          component="label"
          startIcon={<CloudUploadIcon />}
          fullWidth
          sx={{ marginBottom: 2 }}
          disabled={isFormDisabled}
        >
          Select Images
          <input
            type="file"
            hidden
            accept="image/*"
            multiple
            onChange={handleSelectImage}
          />
        </Button>
        {/* Display image previews */}
        <Grid2 container spacing={2}>
          <ImagesPreview
            imagePreviews={imagePreviews}
            titles={titles}
            handleDeleteImage={handleDeleteImage}
            disabled={isFormDisabled}
          />
        </Grid2>
      </Grid2>
    </Grid2>
  );
};

export default InventoryInputFields;
