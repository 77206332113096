// Glossary data
const glossaryData = [
  {
    term: "Actual Cash Value (ACV)",
    definition:
      "ACV refers to the value of an item at the time of loss, considering depreciation.",
    example:
      "A 5-year-old TV originally costing $1,000 may have depreciated to an ACV of $600.",
  },
  {
    term: "Replacement Cost Value (RCV)",
    definition:
      "RCV is the amount required to replace an item with a new one of similar kind and quality, without deducting for depreciation.",
    example:
      "RCV would cover the cost of a new model today, regardless of its age or condition.",
  },
  {
    term: "Depreciation",
    definition:
      "The reduction in the value of an item over time due to wear and tear, age, and obsolescence.",
    example:
      "Furniture loses value as it gets used, decreasing its ACV for insurance claims.",
  },
  {
    term: "Incurred Expenses (Additional Living Expenses)",
    definition:
      "Expenses incurred while your home is being repaired, like hotel stays, meals, or temporary rentals. These costs are covered under Loss of Use or ALE.",
    example:
      "Insurance would cover hotel bills if you need temporary housing during repairs.",
  },
  {
    term: "Appreciation",
    definition:
      "The increase in the value of an asset over time. This can apply to real estate or high-value personal property like art.",
    example: "A rare painting may appreciate from $2,000 to $10,000 over time.",
  },
  {
    term: "Coverage Limits",
    definition:
      "The maximum amount your insurance policy will pay for a covered loss.",
    example:
      "A policy with a $100,000 limit would only cover up to that amount for personal property damage.",
  },
  {
    term: "Deductible",
    definition:
      "The out-of-pocket amount you pay before your insurance covers the rest.",
    example:
      "If your deductible is $1,000 and your loss is $10,000, the insurance pays $9,000 after your contribution.",
  },
  {
    term: "Endorsement (Rider)",
    definition:
      "A modification or addition to your policy for extra coverage on specific items or risks.",
    example:
      "A jewelry endorsement may cover expensive pieces beyond standard policy limits.",
  },
  {
    term: "Exclusion",
    definition:
      "Specific risks or causes of loss not covered by your insurance policy.",
    example:
      "Flood damage is typically excluded from standard home insurance policies.",
  },
  {
    term: "Liability Coverage",
    definition:
      "Protection against claims for bodily injury or property damage caused by you or your family members.",
    example:
      "If a guest is injured in your home, liability coverage helps with medical expenses and legal fees.",
  },
  {
    term: "Personal Property Coverage",
    definition:
      "The part of your insurance policy that covers loss or damage to your belongings.",
    example:
      "If your home is burglarized, this coverage would replace stolen items like TVs and jewelry.",
  },
  {
    term: "Premium",
    definition:
      "The cost of your insurance policy, typically paid monthly or annually.",
    example:
      "A homeowner in a hurricane-prone area may pay higher premiums due to the increased risk.",
  },
  {
    term: "Peril",
    definition:
      "A specific risk or cause of loss covered by your policy, like fire or theft.",
    example:
      "Perils like vandalism may be covered, but other disasters like floods often require additional policies.",
  },
  {
    term: "Replacement Cost",
    definition:
      "The amount it costs to replace damaged items or rebuild your home with similar quality materials.",
    example:
      "Replacement cost differs from market value and focuses on repair or rebuild costs.",
  },
  {
    term: "Rider",
    definition:
      "An endorsement or add-on to your policy for specific items, typically high-value assets like art or jewelry.",
    example:
      "A rider may be added to insure a $10,000 engagement ring separately from your personal property coverage.",
  },
  {
    term: "Subrogation",
    definition:
      "The process by which your insurance company seeks reimbursement from the responsible party after paying out a claim.",
    example:
      "If a neighbor’s tree damages your house and your insurance covers repairs, your insurer may seek reimbursement from their insurer.",
  },
  {
    term: "Underwriting",
    definition:
      "The process of evaluating the risk of insuring a person or property and determining premium rates.",
    example:
      "Underwriting considers factors like the age of your home and claim history to calculate risk.",
  },
  {
    term: "Named Perils Policy",
    definition:
      "A policy that only covers losses caused by perils specifically listed.",
    example:
      "Fire, theft, and vandalism are common perils listed in these policies.",
  },
  {
    term: "Open Perils Policy",
    definition:
      "A policy covering all causes of loss except for those specifically excluded.",
    example:
      "Unless excluded, an open perils policy would cover damages from most incidents.",
  },
  {
    term: "Loss of Use Coverage",
    definition:
      "Insurance that covers additional living expenses if you can’t live in your home during repairs from a covered loss.",
    example:
      "Insurance would cover hotel stays while your home is repaired after a fire.",
  },
  {
    term: "Flood Insurance",
    definition:
      "Separate insurance that covers flood damage, which is typically excluded from standard policies.",
    example:
      "Homeowners in flood-prone areas often need separate flood insurance.",
  },
];

// Acronyms data
const acronymsData = [
  { acronym: "ACV", fullTerm: "Actual Cash Value" },
  { acronym: "RCV", fullTerm: "Replacement Cost Value" },
  { acronym: "HOI", fullTerm: "Homeowners Insurance" },
  { acronym: "ALE", fullTerm: "Additional Living Expenses" },
  { acronym: "FEMA", fullTerm: "Federal Emergency Management Agency" },
  { acronym: "ISO", fullTerm: "Insurance Services Office" },
  { acronym: "NFIP", fullTerm: "National Flood Insurance Program" },
  { acronym: "LTV", fullTerm: "Loan-to-Value Ratio" },
];

export { glossaryData, acronymsData };