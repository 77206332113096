import React from "react";
import { useNavigate } from "react-router-dom";
import { IconButton, Box, Typography } from "@mui/material";

import RefreshIcon from "@mui/icons-material/Refresh";
import InfoIcon from "@mui/icons-material/Info";

import SwipeableLeftBar from "../../utils/SwipeableLeftBar";

const ChatBotHeader = () => {
  const navigate = useNavigate();

  const handleRestartClick = () => {
    // Logic to restart chat (you can define this based on your app)
    navigate(0); // Simulate reload by navigating to the same page
  };

  const handleAboutClick = () => {
    navigate("/about"); // window.location.href = "/about"; // Navigate to about page
  };

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      padding={2}
      bgcolor="header.secondary"
      borderRadius={3}
      sx={{ width: "fit-content", boxSizing: "border-box", boxShadow: 3 }}
    >
      {/* Logo or Title */}
      <Box display="flex" alignItems="center">
        {/* <img src="/path/to/logo.png" alt="HomeGuard AI" style={{ height: 40, marginRight: 8 }} /> */}
        <Typography variant="h6" sx={{ display: { xs: "none", sm: "block" } }}>
          HomeGuard AI Assistant
        </Typography>
      </Box>

      {/* Action Buttons */}
      <Box display="flex" alignItems="center">
        <SwipeableLeftBar />
        <IconButton onClick={handleRestartClick} aria-label="Restart Chat">
          <RefreshIcon />
        </IconButton>
        <IconButton onClick={handleAboutClick} aria-label="About">
          <InfoIcon />
        </IconButton>
      </Box>
    </Box>
  );
};

export default ChatBotHeader;
