import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Drawer, Box, IconButton } from "@mui/material";
import { Menu as MenuIcon } from "@mui/icons-material";
import Menu from "../utils/Menu";

const mainMenuItemStyle = {
  textDecoration: "none",
  color: "rebeccapurple",
};

const SwipeableLeftBar = (props) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const handleNavigate = (path) => {
    navigate(path);
    setOpen(false); // Close the drawer after navigation
  };

  return (
    <Box {...props}>
      <IconButton variant="text" aria-label="menu" onClick={toggleDrawer(true)}>
        <MenuIcon />
      </IconButton>
      <Drawer anchor="left" open={open} onClose={toggleDrawer(false)}>
        <Menu
          sx={{
            minWidth: "35dvw",
            p: 2,
            backgroundColor: "background.paper",
            flexGrow: 1,
          }}
          mainMenuItemStyle={mainMenuItemStyle}
          handleNavigate={handleNavigate}
        />
      </Drawer>
    </Box>
  );
};

export default SwipeableLeftBar;
