const faqData = [
  {
    id: "panel1",
    question: "What is HomeGuard AI?",
    answer: (
      <>
        HomeGuard AI is a user-friendly tool that helps you create and manage a
        detailed home inventory. It works with the <b>“Navigating the Uncharted
        Waters: A Home Inventory and Insurance Claims Workbook”</b> to streamline
        documenting your belongings, ensuring you’re prepared for unexpected
        events.
      </>
    ),
  },
  {
    id: "panel2",
    question: "Can HomeGuard AI read and analyze my insurance policy?",
    answer: (
      <>
        Yes, HomeGuard AI can read and analyze your insurance documents. It will
        provide a clear and concise summary of your policy, including crucial
        coverage details, exclusions, and important dates. Additionally,
        HomeGuard AI can tell you what your policy says about <b>actual cash value
        (ACV)</b>, <b>replacement cost value (RCV)</b>, and <b>coverage limits</b>, ensuring that
        you fully understand your policy and are adequately covered in the event
        of a claim.
      </>
    ),
  },
  {
    id: "panel3",
    question: "How do I create a home inventory using HomeGuard AI?",
    answer: (
      <>
        HomeGuard AI guides you through every room in your home, helping you
        list items such as furniture, electronics, and appliances. You’ll
        document critical details like the item’s condition, purchase location,
        and cost.
      </>
    ),
  },
  {
    id: "panel4",
    question: "Can I use HomeGuard AI to scan my rooms or belongings?",
    answer: (
      <>
        You can use your phone’s camera to upload photos of your belongings or
        hand-drawn room layouts. HomeGuard AI will store these in a cloud-based
        system for easy access later.
      </>
    ),
  },
  {
    id: "panel5",
    question: "Where are my home inventory records stored?",
    answer: (
      <>
        HomeGuard AI integrates with Google Shared Drive and iCloud for secure,
        cloud-based storage. This ensures your home inventory is accessible from
        anywhere and on any device.
      </>
    ),
  },
  {
    id: "panel6",
    question: "How do I upload documents or sketches to HomeGuard AI?",
    answer: (
      <>
        Each page of the HomeGuard Workbook includes a QR code. Simply scan the
        code to upload your documents, sketches, or checklists into your shared
        drive.
      </>
    ),
  },
  {
    id: "panel7",
    question: "Can HomeGuard AI help with insurance claims after a disaster?",
    answer: (
      <>
        Absolutely. HomeGuard AI not only assists in preparing for disasters but
        also helps you rebuild your inventory if something is lost. It guides
        you through recalling missing items, using records and AI-powered
        suggestions.
      </>
    ),
  },
  {
    id: "panel8",
    question: "How does HomeGuard AI help me find forgotten items?",
    answer: (
      <>
        HomeGuard AI provides intelligent suggestions, reminding you to document
        hidden or overlooked items like those in drawers, closets, or under
        furniture. It can also help you estimate item values using tools like
        Google Lens.
      </>
    ),
  },
  {
    id: "panel9",
    question: "Is there any manual input required for my home inventory?",
    answer: (
      <>
        While HomeGuard AI automates much of the process, you may still manually
        input specific details for completeness. The Workbook allows you to log
        information that can be scanned and uploaded for digital record-keeping.
      </>
    ),
  },
  {
    id: "panel10",
    question: "Is HomeGuard AI secure?",
    answer: (
      <>
        Your inventory and records are stored securely in the cloud through
        Google Shared Drive or iCloud. You can access your files anytime while
        keeping them safe from data loss.
      </>
    ),
  },
  {
    id: "panel11",
    question:
      "Can I start using HomeGuard AI if I’ve already created a home inventory?",
    answer: (
      <>
        Yes, HomeGuard AI works with both new and existing inventories. It
        allows you to seamlessly add to or update your current records, ensuring
        you have the most up-to-date information.
      </>
    ),
  },
  {
    id: "panel12",
    question: "What happens if I need to document something?",
    answer: (
      <>
        HomeGuard AI offers AI-powered reminders and suggestions to help you
        document often-overlooked items. Whether it’s forgotten drawers or
        under-furniture storage, HomeGuard AI has you covered.
      </>
    ),
  },
  {
    id: "panel13",
    question: "Does HomeGuard AI assist with valuing my items?",
    answer: (
      <>
        Yes, HomeGuard AI can help estimate the value of your belongings using
        tools like Google Lens for real-time pricing and valuation suggestions.
      </>
    ),
  },
  {
    id: "panel14",
    question:
      "Can HomeGuard AI help me log into Google Shared Drive or create an account?",
    answer: (
      <>
        Yes, HomeGuard AI can assist you with logging into your Google Shared
        Drive account. If you don’t have an account yet, HomeGuard AI can also
        guide you through creating one, making it easy to set up and manage your
        inventory securely.
      </>
    ),
  },
];

export default faqData;
