import React, { createContext, useState, useEffect } from "react";
import axios from "axios";
import { getToken, removeToken } from "../settings/storage";
import { isTokenExpired } from "../settings/verify-token";
import config from "../settings/config";

// Create a context
export const AuthContext = createContext();

// Create a provider component
export const AuthProvider = ({ children }) => {
  const [authorized, setAuthorized] = useState(false);
  const [user, setUser] = useState(null);

  useEffect(() => {
    const checkAuth = async () => {
      const token = await getToken();
      if (token && !isTokenExpired(token)) {
        try {
          const response = await axios.get(`${config.backendUrl}/client/profile`, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          });
          if (response.status === 200 && response.data.data) {
            setUser(response.data.data);
            setAuthorized(true);
          }
        } catch (error) {
          console.error("Failed to fetch profile:", error);
          setAuthorized(false);
        }
      } else {
        setAuthorized(false);
        removeToken();
      }
    };
    checkAuth();
  }, []);

  const signOut = async () => {
    removeToken();
    setAuthorized(false);
    setUser(null);
  };

  return (
    <AuthContext.Provider value={{ authorized, user, signOut, setAuthorized }}>
      {children}
    </AuthContext.Provider>
  );
};
