import axios from "axios";
import config from "../../settings/config";
import { getToken } from "../../settings/storage";

// Process insurance content document (PDF, DOCX, or TXT)
export const processInsuranceContent = async (file) => {
  try {
    const token = await getToken();
    const formData = new FormData();
    formData.append("file", file);

    const response = await axios.post(
      `${config.backendUrl}/client/analysis/process-insurance-content`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          // 'Content-Type': 'multipart/form-data' // Axios automatically sets this for FormData
        }
      }
    );

    return response.data; // Return the response data
  } catch (error) {
    console.error("Error uploading insurance content:", error);
    throw error;
  }
};

// Upload insurance content document (PDF, DOCX, or TXT)
export const uploadInsuranceContent = async (file) => {
  try {
    const token = await getToken();
    const formData = new FormData();
    formData.append("file", file);

    const response = await axios.post(
      `${config.backendUrl}/client/analysis/upload-insurance-content`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          // 'Content-Type': 'multipart/form-data' // Axios automatically sets this for FormData
        }
      }
    );

    return response.data; // Return the response data
  } catch (error) {
    console.error("Error uploading insurance content:", error);
    throw error;
  }
};

// Analyze insurance content for insights and recommendations
export const analyzeInsuranceContent = async (insuranceContent) => {
  try {
    const token = await getToken();
    const response = await axios.post(
      `${config.backendUrl}/client/analysis/analyze-insurance-content`,
      { insurance_content: insuranceContent },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          // "Content-Type": "application/json",
        }
      }
    );

    return response.data; // Return the response data
  } catch (error) {
    console.error("Error analyzing insurance content:", error);
    throw error;
  }
};

// Extract detailed structured information from the insurance content
export const extractInsuranceDetails = async (insuranceContent) => {
  try {
    const token = await getToken();

    const response = await axios.post(
      `${config.backendUrl}/client/analysis/extract-insurance-details`,
      { insurance_content: insuranceContent },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        }
      }
    );

    return response.data; // Return the response data
  } catch (error) {
    console.error("Error extracting insurance details:", error);
    throw error;
  }
};
