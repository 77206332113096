import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ChatBotWrapper from "./components/ChatBotWrapper";
import { getToken } from "../../settings/storage";

const HomeguardChatbot = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [token, setToken] = useState(null);

  useEffect(() => {
    const verifyToken = async () => {
      const token = await getToken(); // Retrieve token
      if (token) {
        setToken(token);
      } else {
        navigate("/sign-in");
      }
      setLoading(false);
    };
    verifyToken(); // Call the async function
  }, [navigate]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!token) {
    return null;
  }
  return <ChatBotWrapper />;
};

export default HomeguardChatbot;
