import React, { useState } from "react";
import BarcodeScannerComponent from "react-qr-barcode-scanner";
import { 
  Box, Typography 
} from '@mui/material';
import { authenticateQrToken } from "../../services/authService";

const QrScanner = () => {
  const [scanResult, setScanResult] = useState("");
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const handleUpdate = async (err, result) => {
    if (err) {
      console.error("QR scan error:", err);
    }

    if (result) {
      console.log("checkpoint qr scanned:", result);
      setScanResult(result.getText()); // This is the qr session result extracted from the QR code
      try {
        // Send the scanned JWT to the authentication endpoint
        const response = await authenticateQrToken();

        if (response.is_authenticated) {
          setIsAuthenticated(true);
          // Store or use access_token as needed
          console.log("Authenticated successfully:", response.access_token);
        }
      } catch (error) {
        console.error("Authentication failed:", error);
      }
    }
  };

  return (
    <Box>
      <Typography
        variant="h5"
        color="text.primary"
        sx={{ mb: 1, fontWeight: "bold" }}
      >
        Scan QR Code to Login
      </Typography>
      {isAuthenticated ? (
        <div>Login successful!</div>
      ) : (
        <BarcodeScannerComponent
          width={500}
          height={500}
          onUpdate={handleUpdate}
        />
      )}
      {scanResult && <p>Scanned Token: {scanResult}</p>}
    </Box>
  );
};

export default QrScanner;
