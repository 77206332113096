import React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Header from '../components/layouts/ContentHeader';
import Footer from '../components/layouts/ContentFooter';

import { aboutContent } from '../consts/aboutData'; // Import the data

export default function About() {
  const { title, introduction, sections } = aboutContent;

  return (
    <Box>
      <Header />
      <Box sx={{ width: '100%', minHeight: '100vh' }}>
        <Container sx={{ pt: 20, pb: 6 }}>
          <Typography variant="h5" sx={{ fontWeight: 'bold' }} align="center" gutterBottom>
            {title}
          </Typography>

          <Typography variant="body1" gutterBottom sx={{ mt: 4 }}>
            {introduction}
          </Typography>

          {sections.map((section, index) => (
            <Box key={index}>
              <Typography variant="h5" sx={{ mt: 6, mb: 4 }}>
                {section.title}
              </Typography>

              {section.features.map((feature, featureIndex) => (
                <Box key={featureIndex}>
                  <Typography variant="h6" sx={{ fontWeight: 'bold', mt: 3 }}>
                    {feature.title}
                  </Typography>
                  <Typography variant="body2" sx={{ mt: 1 }}>
                    {feature.description}
                  </Typography>
                </Box>
              ))}
            </Box>
          ))}
        </Container>
      </Box>
      <Footer />
    </Box>
  );
}
