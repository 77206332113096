import React, { useEffect, useState } from "react";
import { Box, Container, Link, Typography } from "@mui/material";

const logoStyle = {
  width: "80px",
  height: "auto",
};

function Copyright() {
  const [imageSrc, setLogoSrc] = useState("/logo512.png");

  useEffect(() => {
    const handleResize = () => {
      if (window.matchMedia("(max-width: 768px)").matches) {
        setLogoSrc("/logo192.png"); // Mobile image
      } else {
        setLogoSrc("/logo512.png"); // Web image
      }
    };

    // Call the function on mount
    handleResize();

    // Add an event listener for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
      }}
    >
      <Typography variant="body1">{"Copyright © "}</Typography>
      <Box>
        <img src={imageSrc} style={logoStyle} alt="logo" />
      </Box>
      <Typography variant="body1">{new Date().getFullYear()}</Typography>
    </Box>
  );
}

export default function Footer() {
  return (
    <Box
      sx={{
        position: "relative",
        backgroundColor: "header.secondary",
        textAlign: { sm: "center", md: "left" },
        zIndex: 1111
      }}
    >
      <Container>
        <Box
          sx={{
            display: "flex",
            justifyContent: { xs: "center", sm: "space-between" },
            alignItems: "center",
            width: "100%",
          }}
        >
          <Copyright />
          <Box sx={{ display: { xs: "none", sm: "block" } }}>
            <Link color="text.secondary" href="#">
              Privacy Policy
            </Link>
            <Typography display="inline" sx={{ mx: 0.5, opacity: 0.5 }}>
              &nbsp;•&nbsp;
            </Typography>
            <Link color="text.secondary" href="#">
              Terms of Service
            </Link>
          </Box>
        </Box>
      </Container>
    </Box>
  );
}
