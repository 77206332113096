import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/material";

import Header from "../components/layouts/Header";
import Footer from "../components/layouts/Footer";
import "./Landing.css"; // Custom styles for responsive layout

const LandingPage = () => {
  const navigate = useNavigate();
  const [imageSrc, setImageSrc] = useState("/assets/img/web-background.png");

  useEffect(() => {
    const handleResize = () => {
      if (window.matchMedia("(max-width: 768px)").matches) {
        setImageSrc("/assets/img/mobile-background.png"); // Mobile image
      } else {
        setImageSrc("/assets/img/web-background.png"); // Web image
      }
    };

    // Call the function on mount
    handleResize();

    // Add an event listener for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleRobotClick = () => {
    navigate("/chatbot");
  };

  const handlePaperClick = () => {
    navigate("/about");
  };

  return (
    <Box sx={{ backgroundSize: "cover" }}>
      <Header />

      {/* Background with robot and smart paper */}
      <div className="image-container">
        {/* Background image */}
        <img src={imageSrc} alt="Background" className="background-image" />

        {/* Robot image - clickable */}
        <img
          src="/assets/img/robot.png"
          alt="AI Robot"
          className="robot-image"
          onClick={handleRobotClick}
        />

        {/* Smart paper image - clickable */}
        <img
          src="/assets/img/smart-paper.png"
          alt="Smart Paper"
          className="smart-paper-image"
          onClick={handlePaperClick}
        />
      </div>

      <Footer />
    </Box>
  );
};

export default LandingPage;
