export const convertSnakeToCamel = (obj) => {
  if (Array.isArray(obj)) {
    return obj.map((item) => convertSnakeToCamel(item));
  } else if (obj !== null && obj && typeof obj === "object") {
    return Object.keys(obj).reduce((acc, key) => {
      const newKey = key.replace(/(_\w)/g, (matches) =>
        matches[1].toUpperCase()
      );
      acc[newKey] = convertSnakeToCamel(obj[key]);
      return acc;
    }, {});
  }
  return obj;
};
