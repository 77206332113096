// const backendDomain = "http://localhost:8000";
const backendDomain = "https://homeguard-ai.com";

const config = {
  backendDomain,
  backendUrl: `${backendDomain}/v1/api`,
  stripe_publishable_key: "pk_test_51Q0VFN11mtB6Xurq7zwEYzXbx31bVC4MRNSdUp3oTulQUj4Fn5qP45h10aaDTnVKCHirzGI5JF8doYGqzFLLBnja00glXW3ol5",
};

export default config;
