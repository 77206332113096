import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { toast } from "react-toastify";

import CircularProgress from "@mui/material/CircularProgress";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid2 from "@mui/material/Grid2";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Alert from "@mui/material/Alert";
import Container from "@mui/material/Container";

import { signUp } from "../../services/authService"; // Import API function
import "./auth.css";

export default function SignUp() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const firstName = data.get("firstName");
    const lastName = data.get("lastName");
    const email = data.get("email");
    const password = data.get("password");
    const confirmPassword = data.get("confirmPassword");

    setError(null);

    if (!firstName) {
      setError("First Name is required");
      return;
    }
    if (!lastName) {
      setError("Last Name is required");
      return;
    }
    if (!email) {
      setError("Email is required");
      return;
    }
    if (!password) {
      setError("Password is required");
      return;
    } else if (password.length < 8) {
      setError("Password must be at least 8 characters long.");
      return;
    } else if (password !== confirmPassword) {
      setError("Passwords do not match.");
      return;
    }

    const payload = {
      first_name: firstName,
      last_name: lastName,
      email: email,
      password: password,
    };

    setIsLoading(true);

    try {
      await signUp(payload); // Call the API service
      toast.success("Signed up successfully!"); // Success toast
      navigate("/sign-in");
    } catch (err) {
      setError(err.message);
      toast.error(err.message); // Error toast
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Grid2 container component="main" sx={{ height: "100vh" }}>
      <Grid2 className="background-container" size={{ xs: 12, sm: 6 }} />
      <Grid2
        container
        size={{ xs: 12, sm: 6 }}
        justifyContent="center"
        alignItems="center"
        sx={{ backgroundColor: "white" }}
      >
        <Container
          maxWidth="sm"
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {isLoading && <CircularProgress />}

          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign Up
          </Typography>
          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit}
            sx={{ mt: 3 }}
          >
            <Grid2 container spacing={2}>
              <Grid2 size={{ xs: 6, sm: 6 }}>
                <TextField
                  autoComplete="given-name"
                  name="firstName"
                  required
                  fullWidth
                  id="firstName"
                  label="First Name"
                  autoFocus
                />
              </Grid2>
              <Grid2 size={{ xs: 6, sm: 6 }}>
                <TextField
                  autoComplete="family-name"
                  name="lastName"
                  required
                  fullWidth
                  id="lastName"
                  label="Last Name"
                />
              </Grid2>
              <Grid2 size={12}>
                <TextField
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                />
              </Grid2>
              <Grid2 size={12}>
                <TextField
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="new-password"
                />
              </Grid2>
              <Grid2 size={12}>
                <TextField
                  required
                  fullWidth
                  name="confirmPassword"
                  label="Confirm Password"
                  type="password"
                  id="confirmPassword"
                  autoComplete="new-password"
                />
              </Grid2>
            </Grid2>
            {error && (
              <Alert severity="error" sx={{ mt: 2 }}>
                {error}
              </Alert>
            )}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              disabled={isLoading} // Disable button while loading
            >
              Sign Up
            </Button>
            <Grid2 container justifyContent="flex-end">
              <Grid2>
                <Link to="/sign-in" variant="body2">
                  Already have an account? Sign In
                </Link>
              </Grid2>
            </Grid2>
          </Box>
        </Container>
      </Grid2>
    </Grid2>
  );
}
