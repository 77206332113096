import React from "react";
import { Box } from "@mui/material";

import ChatBot from "../components/Chatbot/index";

const ChatbotPage = () => {
  return (
    <Box sx={{ height: "100vh" }}>
      <ChatBot />
    </Box>
  );
};

export default ChatbotPage