import React from "react";

import { Box, Container } from "@mui/material";

import Header from "../components/layouts/ContentHeader";
import Footer from "../components/layouts/ContentFooter";
import QrScanner from "../components/QrScanner/index.js";

export default function Pricing() {
  return (
    <Box>
      <Header />
      <Box
        sx={{
          width: "100%",
          height: "100vh",
          backgroundSize: "100% 50%",
          backgroundRepeat: "no-repeat",
        }}
      >
        <Container
          sx={{
            pt: 20,
            pb: { xs: 4, sm: 6 },
            display: "flex",
            flexDirection: "column",
            gap: { xs: 3, sm: 4 },
          }}
        >
          <QrScanner />
        </Container>
      </Box>
      <Footer />
    </Box>
  );
}
