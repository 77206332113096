import React from "react";
import { useNavigate } from "react-router-dom";
import ChatBot from "react-chatbotify";

import flowComposer from "../config/flow";
// import styles from "./styles";
import settings from "../config/settings";
import "./chatbot.css";

const ChatBotWrapper = () => {
  const navigate = useNavigate();
  const unauthorizedHandler = () => navigate("/sign-in");
  const flow = flowComposer(unauthorizedHandler);

  return (
    <ChatBot
      // styles={styles}
      settings={settings}
      flow={flow}
    />
  );
};

export default ChatBotWrapper;
