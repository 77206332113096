export const setToken = async (token) => {
  try {
    await localStorage.setItem("token", token);
  } catch (e) {
    console.log(e);
  }
};

export const getToken = async () => {
  try {
    const item = await localStorage.getItem("token");
    return item;
  } catch (e) {
    console.log(e);
    return null;
  }
};

export const removeToken = async () => {
  try {
    await localStorage.removeItem("token");
  } catch (e) {
    console.error(e);
  }
};
