import React from "react";

import Box from "@mui/material/Box";

import Header from "../components/layouts/ContentHeader";
import Footer from "../components/layouts/ContentFooter";

export default function Contact() {
  return (
    <Box>
      <Header />
      <Box
        sx={{
          width: "100%",
          height: "100vh",
          backgroundSize: "100% 50%",
          backgroundRepeat: "no-repeat",
        }}
      >
        <span>Not Completed</span>
      </Box>
      <Footer />
    </Box>
  );
}
