import { toast } from "react-toastify";
import InventoryInputForm from "../../utils/InventoryInputForm";
import AnalysisResult from "../../utils/AnalysisResult";
import { createInventoryItem } from "../../../services/client/inventoryItemsService";
import {
  uploadInsuranceContent,
  analyzeInsuranceContent,
} from "../../../services/client/analysisService";
// import { initializeNotificationWebSocket } from "../../../services/client/wsService";
import { fetchBotResponse } from "../../../services/botService";

import {
  WELCOME_MESSAGE,
  START_MESSAGE,
  START_NEW_OPTION,
  START_NEW_RESPONSE_MESSAGE,
  START_CONTINUE_OPTION,
  START_CONTINUE_RESPONSE_MESSAGE,
  START_ANALYZE_OPTION,
  START_ANALYZE_RESPONSE_MESSAGE,
  START_HELP_OPTION,
  START_HELP_RESPONS_MESSAGE,
  REQUEST_INVENTORY_INPUT_MESSAGE,
  INVENTORY_INPUT_YES_RESPONSE_MESSAGE,
  INVENTORY_INPUT_NO_RESPONSE_MESSAGE,
  INVENTORY_INPUT_CAUTION_MESSAGE,
  INVENTORY_CREATE_OK_MESSAGE,
  INVENTORY_INPUT_EXIT_RESPONSE_MESSAGE,
  USER_LIST_ITEMS_MESSAGE,
  LIST_ITEMS_RESPONSE_MESSAGE,
  REQUEST_INSURANCE_POLICY_UPLOAD_MESSAGE,
  USER_GO_CHAT_FREELY_MESSAGE,
  GO_CHAT_FREELY_RESPONSE_MESSAGE,
  INVENTORY_ITEMS_LIST_MESSAGE,
  START_AGAIN_MESSAGE,
  CHAT_FREELY_START_MESSAGE,
  UNKNOWN_INPUT__RESPONSE_MESSAGE,
} from "./messages";

const flowComposer = (unauthorizedHandler) => ({
  start: {
    message: WELCOME_MESSAGE,
    chatDisabled: true,
    transition: { duration: 2000 },
    path: "request_start",
  },

  request_start: {
    message: START_MESSAGE,
    options: [
      START_NEW_OPTION,
      // START_CONTINUE_OPTION,
      START_ANALYZE_OPTION,
      START_HELP_OPTION,
    ],
    chatDisabled: true,
    path: "request_start_response_process",
  },
  request_start_response_process: {
    transition: { duration: 0 },
    chatDisabled: true,
    path: async (params) => {
      let message = "";
      let path = "";
      switch (params.userInput) {
        case START_NEW_OPTION:
          message = START_NEW_RESPONSE_MESSAGE;
          path = "request_inventory_input";
          break;
        case START_CONTINUE_OPTION:
          message = START_CONTINUE_RESPONSE_MESSAGE;
          path = "request_inventory_input";
          break;
        case START_ANALYZE_OPTION:
          message = START_ANALYZE_RESPONSE_MESSAGE;
          path = "request_insurance_policy_upload";
          break;
        case START_HELP_OPTION:
          message = START_HELP_RESPONS_MESSAGE;
          path = "request_inventory_input";
          break;
        default:
          return "unknown_input";
      }
      await params.injectMessage(message);
      return path;
    },
  },

  request_inventory_input: {
    transition: { duration: 1000 },
    message: REQUEST_INVENTORY_INPUT_MESSAGE,
    // options: ["Yes", "No"],
    chatDisabled: true,
    path: "inventory_input_caution",
  },

  inventory_input_flag_process: {
    transition: { duration: 0 },
    chatDisabled: true,
    path: async (params) => {
      let message = "";
      let path = "";
      switch (params.userInput) {
        case "Yes":
          message = INVENTORY_INPUT_YES_RESPONSE_MESSAGE;
          path = "inventory_input_caution";
          break;
        case "No":
          message = INVENTORY_INPUT_NO_RESPONSE_MESSAGE;
          path = "chat_freely_start";
          break;
        default:
          return "unknown_input";
      }
      await params.injectMessage(message);
      return path;
    },
  },
  inventory_input_caution: {
    message: INVENTORY_INPUT_CAUTION_MESSAGE,
    transition: { duration: 1000 },
    chatDisabled: true,
    path: "inventory_input",
  },

  inventory_input: {
    component: (props) => (
      <InventoryInputForm
        onSubmit={async (data) => {
          try {
            let path = "unknown_data_submitted";
            if (data.isExit) {
              path = "inventory_input_exit";
            } else {
              await createInventoryItem(data);
              path = "inventory_create_ok";
            }
            props.goToPath(path);
          } catch (error) {
            // Handle error properly
            console.error("Failed to create inventory item:", error.message);
            toast.error(error.message, { autoClose: 1000 });
            if (error.response && error.response.status === 401) {
              unauthorizedHandler();
              // window.location.href = "/sign-in";
            }
          }
        }}
      />
    ),
    chatDisabled: true,
    asMessage: true, // Render the component as a message
  },
  inventory_create_ok: {
    message: (params) => {
      toast.success("Inventory item created successfully!", {
        autoClose: 1500,
      });
      // params.showToast(INVENTORY_CREATE_OK_MESSAGE, 2000);
    },
    chatDisabled: true,
  },
  inventory_create_error: {
    message: (params) => {
      toast.error("Inventory item created successfully!", {
        autoClose: 1500,
      });
      params.showToast(INVENTORY_CREATE_OK_MESSAGE, 2000);
    },
    chatDisabled: true,
  },
  inventory_input_exit: {
    message: INVENTORY_INPUT_EXIT_RESPONSE_MESSAGE,
    options: {
      items: [USER_LIST_ITEMS_MESSAGE, USER_GO_CHAT_FREELY_MESSAGE],
      sendOutput: false,
    },
    chatDisabled: true,
    path: "post_exit_path",
  },
  post_exit_path: {
    transition: { duration: 0 },
    chatDisabled: true,
    path: async (params) => {
      let message = "";
      let path = "";
      switch (params.userInput) {
        case USER_LIST_ITEMS_MESSAGE:
          message = LIST_ITEMS_RESPONSE_MESSAGE;
          path = "inventory_items_list";
          setTimeout(() => {
            window.location.href = "/profile";
          }, 2000);
          break;
        case USER_GO_CHAT_FREELY_MESSAGE:
          message = GO_CHAT_FREELY_RESPONSE_MESSAGE;
          path = "chat_freely_start";
          break;
        default:
          return "unknown_input";
      }
      await params.injectMessage(message);
      return path;
    },
  },

  inventory_items_list: {
    message: INVENTORY_ITEMS_LIST_MESSAGE,
  },
  chat_freely_start: {
    message: CHAT_FREELY_START_MESSAGE,
    path: "chat_freely",
  },

  // --- option 3: analyze insurance policy ---
  request_insurance_policy_upload: {
    message: (params) => REQUEST_INSURANCE_POLICY_UPLOAD_MESSAGE,
    chatDisabled: true,
    file: async (params) => {
      if (params.files && params.files[0]) {
        try {
          // console.log('checkpoint params:', params)
          await params.injectMessage("Uploading and extracting...");
          const upload_response = await uploadInsuranceContent(params.files[0]);
          // const websocket = initializeNotificationWebSocket(
          //   (messageData) => {
          //     if (messageData.type === "process_status") {
          //       console.log(`Process Status: ${messageData.message}`);
          //     } else if (messageData.type === "subscription_expiry") {
          //       console.log(
          //         `Subscription Notification: ${messageData.message}`
          //       );
          //     }
          //     if (messageData.data) {
          //       console.log("Analysis Result:", messageData.data);
          //     }
          //   },
          //   () => {
          //     console.log("WebSocket error occurred.");
          //   },
          //   () => {
          //     console.log("WebSocket connection closed.");
          //   }
          // );
          if (upload_response.data) {
            //success
            await params.injectMessage("Analyzing...");
            const analysis_response = await analyzeInsuranceContent(
              upload_response.data.extracted_text
            );
            console.log('checkpoint', analysis_response)
            await params.injectMessage(<AnalysisResult analysis={analysis_response.data} />);
            await params.goToPath("request_insurance_policy_upload");
          } else {
            //failed
            await params.injectMessage(
              "Analyzing failed. Please try with other file"
            );
            toast.error(upload_response.message, { autoClose: 2000 });
            await params.goToPath("request_insurance_policy_upload");
          }
        } catch (error) {
          // console.error("Error uploading insurance content:", error);
          await params.injectMessage(
            "Uploading and extracting failed. Please try with other file"
          );
          toast.error(error.message, { autoClose: 2000 });
          await params.goToPath("request_insurance_policy_upload");
        }
      } else {
        console.error("No file found in params.files.");
        toast.error("No file found! Upload the file again.", {
          autoClose: 2000,
        });
      }
    },
  },

  chat_freely: {
    message: async (params) => {
      const result = await fetchBotResponse();
      return result;
    },
    path: "chat_freely",
  },

  // --- fallback to select the service option
  request_start_again: {
    message: START_AGAIN_MESSAGE,
    options: [
      START_NEW_OPTION,
      // START_CONTINUE_OPTION,
      START_ANALYZE_OPTION,
      START_HELP_OPTION,
    ],
    chatDisabled: true,
    path: "request_start_response_process",
  },

  // --- exception path
  unknown_input: {
    message: UNKNOWN_INPUT__RESPONSE_MESSAGE,
    options: ["Yes", "No"],
    path: "inventory_input_flag_process",
  },
});

export default flowComposer;
