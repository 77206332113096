import * as React from 'react';

import Header from '../components/layouts/ContentHeader';
import Footer from '../components/layouts/ContentFooter';
import EditAccount from '../components/Profile/EditAccount';
import ChangePassword from '../components/Profile/ChangePassword';
import SavedInventory from '../components/Profile/SavedInventory';
import MySubscription from '../components/Profile/MySubscription';
import { Box, Container, Tab, Tabs } from '@mui/material';

export default function Profile() {
  const [value, setValue] = React.useState(0);

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  const a11yProps = (index) => {
    return {
      id: `tab-${index}`,
      'aria-controls': `tabpanel-${index}`,
    };
  }

  return (
    <Box>
      <Header />
      <Box
        sx={{
          width: '100%',
          minHeight: 'calc(100vh - 119px)',
          backgroundImage: 'linear-gradient(180deg, #CEE5FD, #FFF)',
          backgroundSize: '100% 50%',
          backgroundRepeat: 'no-repeat',
        }}
      >
        <Container
          sx={{
            pt: 20,
            pb: { xs: 4, sm: 6 },
            display: 'flex',
            flexDirection: 'column',
            gap: { xs: 3, sm: 4 },
          }}
        >
          <Tabs
            value={value}
            onChange={handleTabChange}
            textColor="secondary"
            indicatorColor="secondary"
            aria-label=""
            variant="scrollable"
          >
            <Tab label="Saved Inventory" sx={{fontWeight: 'bold', fontSize: '1rem'}} {...a11yProps(0)} />
            <Tab label="My Subscription" sx={{fontWeight: 'bold', fontSize: '1rem'}} {...a11yProps(1)} />
            <Tab label="Change Password" sx={{fontWeight: 'bold', fontSize: '1rem'}} {...a11yProps(2)} />
            <Tab label="My Account" sx={{fontWeight: 'bold', fontSize: '1rem'}} {...a11yProps(3)} />
          </Tabs>
          <Box
            role="tabpanel"
            hidden={value !== 0}
            id={`tabpanel-0`}
            aria-labelledby={`tab-0`}
          >
            <SavedInventory />
          </Box>
          <Box
            role="tabpanel"
            hidden={value !== 1}
            id={`tabpanel-1`}
            aria-labelledby={`tab-1`}
          >
            <MySubscription />
          </Box>
          <Box
            role="tabpanel"
            hidden={value !== 2}
            id={`tabpanel-3`}
            aria-labelledby={`tab-2`}
          >
            <ChangePassword />
          </Box>
          <Box
            role="tabpanel"
            hidden={value !== 3}
            id={`tabpanel-4`}
            aria-labelledby={`tab-3`}
          >
            <EditAccount />
          </Box>
        </Container>
      </Box>
      <Footer />
    </Box>
  );
}