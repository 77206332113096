import React, { useState } from 'react';

import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import TextField from '@mui/material/TextField';
import Pagination from '@mui/material/Pagination';

import Header from "../components/layouts/ContentHeader";
import Footer from "../components/layouts/ContentFooter";

import { glossaryData, acronymsData } from "../consts/glossaryData";

// Constants
const ITEMS_PER_PAGE = 5; // Number of glossary items per page

export default function Glossary() {
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);

  // Filter glossary terms based on search input
  const filteredGlossaryData = glossaryData.filter((item) =>
    item.term.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Calculate pagination
  const pageCount = Math.ceil(filteredGlossaryData.length / ITEMS_PER_PAGE);
  const displayedItems = filteredGlossaryData.slice(
    (currentPage - 1) * ITEMS_PER_PAGE,
    currentPage * ITEMS_PER_PAGE
  );

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setCurrentPage(1); // Reset to first page when search is used
  };

  const handleChangePage = (event, value) => {
    setCurrentPage(value);
  };

  return (
    <Box>
      <Header />
      <Box sx={{ width: "100%", minHeight: "100vh" }}>
        <Container sx={{ pt: 20, pb: 6 }}>
          <Typography variant="h4" align="center" gutterBottom>
            Homeowner’s Glossary of Insurance Terms
          </Typography>

          {/* Search Bar */}
          <Box sx={{ mb: 4, display: "flex", justifyContent: "center" }}>
            <TextField
              label="Search Terms"
              variant="outlined"
              value={searchTerm}
              onChange={handleSearch}
              sx={{ width: "50%" }}
              placeholder="Search glossary terms..."
            />
          </Box>

          {/* Glossary terms */}
          <Box>
            {displayedItems.map((item, index) => (
              <Box key={index} sx={{ mb: 4 }}>
                <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                  {item.term}
                </Typography>
                <Typography variant="body2">
                  <b>Definition:</b> {item.definition}
                </Typography>
                <Typography variant="body2" sx={{ mt: 1 }}>
                  <b>Example:</b> {item.example}
                </Typography>
              </Box>
            ))}
          </Box>

          {/* Pagination */}
          {filteredGlossaryData.length > ITEMS_PER_PAGE && (
            <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
              <Pagination
                count={pageCount}
                page={currentPage}
                onChange={handleChangePage}
                color="primary"
              />
            </Box>
          )}

          {/* Acronyms section */}
          <Typography variant="h5" align="center" sx={{ mt: 6, mb: 4 }}>
            Common Insurance Acronyms
          </Typography>
          <Box>
            {acronymsData.map((acronymItem, index) => (
              <Typography key={index} variant="body2" sx={{ mb: 1 }}>
                <b>{acronymItem.acronym}:</b> {acronymItem.fullTerm}
              </Typography>
            ))}
          </Box>
        </Container>
      </Box>
      <Footer />
    </Box>
  );
}
