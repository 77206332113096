import axios from "axios";
import config from "../settings/config";
import { getToken, setToken, removeToken } from "../settings/storage";

export const signIn = async (email, password) => {
  const payload = { email, password };

  const response = await fetch(`${config.backendUrl}/auth/client/signin`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });

  const jsonRes = await response.json();

  if (!response.ok) {
    throw new Error(jsonRes.detail || "Sign-in failed");
  }

  await setToken(jsonRes.token);
  return jsonRes;
};

export const signUp = async (payload) => {
  const response = await fetch(`${config.backendUrl}/auth/client/signup`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });

  const jsonRes = await response.json();

  if (!response.ok) {
    throw new Error(jsonRes.detail || "Sign-up failed");
  }

  return jsonRes;
};

export const signOut = () => {
  getToken().then((token) => {
    fetch(`${config.backendUrl}/auth/client/signout`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        response.json().then((jsonRes) => {
          removeToken().then(() => {
            // console.log(jsonRes)
          });
        });
      })
      .catch((err) => {
        console.log(err);
      });
  });
};

export const adminSignOut = () => {
  getToken().then((token) => {
    fetch(`${config.backendUrl}/auth/admin/signout`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        response.json().then((jsonRes) => {
          removeToken().then(() => {
            // console.log(jsonRes)
          });
        });
      })
      .catch((err) => {
        console.log(err);
      });
  });
};

// Function to generate a QR code
export const generateQrCode = async () => {
  try {
    const response = await axios.get(
      `${config.backendUrl}/auth/client/generate_qr`
    );
    return response.data;
  } catch (error) {
    throw new Error("Failed to generate QR code");
  }
};

// Function to validate a QR token after it has been scanned
export const authenticateQrToken = async (qrToken) => {
  try {
    const access_token = await getToken();
    const response = await axios.post(
      `${config.backendUrl}/auth/client/authenticate-with-qr_token`,
      {
        qr_token: qrToken,
        access_token,
      }
    );
    return response.data;
  } catch (error) {
    throw new Error("Failed to authenticate with QR token");
  }
};

// Function to check if the QR token was successful
export const checkQrToken = async (qrToken) => {
  try {
    const response = await axios.post(
      `${config.backendUrl}/auth/client/check_qr_auth/`,
      { qr_token: qrToken }
    );
    await setToken(response.data.access_token)
    return response.data;
  } catch (error) {
    throw new Error("Failed to check QR token");
  }
};
