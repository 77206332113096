import React, { useState } from "react";
import {
  Typography,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  IconButton,
  Modal,
  Box,
  Tooltip,
} from "@mui/material";
import {
  Delete as DeleteIcon,
  ZoomIn as ZoomInIcon,
} from "@mui/icons-material";

const ImagesPreview = ({
  imagePreviews,
  titles,
  handleDeleteImage,
  disabled,
}) => {
  const [modalImage, setModalImage] = useState(null);
  const [modalTitle, setModalTitle] = useState("");

  const handleImageClick = (src, title) => {
    setModalImage(src);
    setModalTitle(title);
  };

  const handleCloseModal = () => {
    setModalImage(null);
    setModalTitle("");
  };

  return (
    <Box>
      {/* Image Previews with Titles and Delete Button */}
      <ImageList sx={{ width: "100%", height: "300px" }} cols={3}>
        {imagePreviews.map((preview, index) => (
          <ImageListItem key={index}>
            <img
              src={preview}
              alt={titles[index]}
              loading="lazy"
            />
            <ImageListItemBar
              actionIcon={
                <>
                  <Tooltip title={titles[index]} arrow>
                    <IconButton
                      aria-label="zoom image"
                      onClick={() => handleImageClick(preview, titles[index])}
                      sx={{ color: "white" }}
                      disabled={disabled}
                    >
                      <ZoomInIcon />
                    </IconButton>
                  </Tooltip>
                  <IconButton
                    aria-label="delete image"
                    onClick={() => handleDeleteImage(index)}
                    sx={{ color: "white" }}
                    disabled={disabled}
                  >
                    <DeleteIcon />
                  </IconButton>
                </>
              }
            />
          </ImageListItem>
        ))}
      </ImageList>

      {/* Modal for Image Zoom View */}
      <Modal open={!!modalImage} onClose={handleCloseModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            maxWidth: "90vw",
            maxHeight: "90vh",
            overflow: "auto",
          }}
        >
          {/* Image Title in the Modal */}
          <Typography variant="h6" sx={{ marginBottom: 2 }}>
            {modalTitle}
          </Typography>
          <img
            src={modalImage}
            alt={modalTitle}
            style={{ width: "100%", height: "auto" }}
          />
        </Box>
      </Modal>
    </Box>
  );
};

export default ImagesPreview;
