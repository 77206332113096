import { createTheme } from '@mui/material/styles';

// A custom theme for this app
const theme = createTheme({
  palette: {
    header: {
      main: '#262f30',
      secondary: '#7B68EE'
    },
    background: {
      default: 'white',
    },
    title: {
      main: '#ffffff', // Bold white for title
    },
    subtitle: {
      main: '#ffa500', // Soft orange for subtitle
    },
    description: {
      main: '#8b8b8b', // Dark grey for description
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
    },
  },
});

export default theme;
