// data/aboutContent.js
export const aboutContent = {
  title:
    "HomeGuard AI: Your Smart Companion for Home Inventory and Insurance Claims",
  introduction:
    "HomeGuard AI is a user-friendly tool designed to work seamlessly with the “Navigating the Uncharted Waters: A Home Inventory and Insurance Claims Workbook” to make creating a home inventory simple and stress-free. Whether preparing for the unexpected or dealing with the aftermath of a home disaster, HomeGuard AI and the accompanying workbook are here to guide you every step of the way.",
  sections: [
    {
      title: "What Can HomeGuard AI Do for You?",
      features: [
        {
          title: "1. Create a Detailed Home Inventory",
          description:
            "HomeGuard AI, alongside the “Navigating the Uncharted Waters” Workbook, helps you document every room...",
        },
        {
          title: "2. Room-by-Room Guidance",
          description:
            "The AI chatbot takes you room by room, ensuring you don’t miss a single item. Using the app or phone camera, you can sketch room layouts, add photos of your belongings, and upload these to the shared drive.",
        },
        {
          title: "3. Cloud-Based Storage",
          description:
            "With HomeGuard AI’s integration with Google Shared Drive and cloud databse service, you can securely store your entire home inventory in the cloud. This way, all of your documentation is easily accessible, no matter where you are or what device you’re using.",
        },
        {
          title: "4. QR Code Scanning for Easy Uploads",
          description:
            "If you’re using the HomeGuard Workbook, each page includes a QR code that lets you quickly upload hand-drawn room sketches, checklists, and inventory lists to your shared drive. No more tedious paperwork—just scan and upload!",
        },
        {
          title: "5. Insurance Claim Assistance",
          description:
            "HomeGuard AI is for more than just preparation. If you’ve experienced a disaster, it helps you rebuild an inventory from memory, guiding you through recreating what was lost. It can even help you identify missing items based on past photos or details.",
        },
        {
          title: "6. AI-Powered Suggestions",
          description:
            "The AI assistant offers intelligent suggestions, reminding you to document hidden items like what’s in drawers, closets, or under furniture. You’ll also get help estimating the value of items using tools like Google Lens.",
        },
        {
          title: "7. Peace of Mind",
          description:
            "HomeGuard AI ensures that your home and belongings are documented, so you’re always prepared for an insurance claim. Whether starting from scratch or adding to an existing inventory, HomeGuard AI ensures that nothing is forgotten.",
        },
      ],
    },
  ],
};
